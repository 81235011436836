<template>
  <div class="preview-comp-wrap">
    <div class="header">
      预览
      <i
        class="el-icon-close"
        @click="closePreview"
      ></i>
    </div>
    <div class="preview-content">
      <div class="process-name">
        {{`【${processName}】`}}
      </div>
      <div class="tab-wrap">
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane
            label="流程表单"
            name="first"
          >
          </el-tab-pane>
          <el-tab-pane
            label="流程信息"
            name="second"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="tab-content">
        <div
          v-if="activeName === 'first'"
          class="is-scroll-y-black"
          :style="'max-height:'+(wrapHeight)+'px;height:'+wrapHeight+'px;overflow-y:auto'"
        >
          <div class="preview-code">
            <FormBuild
              style="height: 100%;"
              :formTemplate="formTemplate"
              :models="models"
              ref="formbuild"
              v-if="formVisible"
            />
          </div>
        </div>
        <div
          v-if="activeName === 'second'"
          :style="'height:'+wrapHeight+'px'"
        >
          <preview-bpmn
            v-if="showPreview"
            :XMLString="XMLString"
            :nodeList="nodeList"
          ></preview-bpmn>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import FormBuild from '@/views/workOrderCenter/dynamicForm/packages/form/src/form-build/index.vue'
import previewBpmn from '@/components/bpmnBuilder/previewBpmn'
import api from '@/api'
export default {
  data () {
    return {
      showPreview: false,
      XMLString: null,
      nodeList: null,
      activeName: 'first',
      formVisible: false,
      formTemplate: {},
      models: {}
    }
  },
  props: {
    configId: {
      type: Number
    },
    processName: {
      type: String
    }
  },
  computed: {
    wrapHeight: () => {
      return document.documentElement.clientHeight - 165
    }
  },
  components: {
    FormBuild,
    previewBpmn
  },
  mounted () {
    // console.log(this.configId)
    this.init()
  },
  methods: {
    initBpmn () {
      const params = {
        configId: this.configId
      }
      this.axios.get(api.getFlowNodeInfo, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('res.data.data', res.data.data)
          if (resData.camundaFile === null) {
            return
          }
          this.XMLString = resData.camundaFile
          this.nodeList = resData.flowNodeList
          this.showPreview = true
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    init () {
      this.axios.get(api.getWorkConfigHtml, { params: { configId: this.configId } }).then(res => {
        const json = res.data.data
        /* eslint-disable */
        this.formTemplate = eval('(' + json + ')')
        this.formVisible = true
        /* eslint-enable */
      })
    },
    handleClick (tab, event) {
      // console.log(tab.name, event)
      if (this.activeName === 'second') {
        this.initBpmn()
      }
    },
    closePreview () {
      this.$emit('closePreviewEmit')
    }
  }
}
</script>

<style lang="stylus" scoped>
.preview-comp-wrap
  position fixed
  left 0
  top 0
  bottom 0
  right 0
  width 100%
  height calc(100%)
  background-color #1E1E23
  z-index 1111
  overflow hidden
  .header
    position relative
    height 88px
    background-color #272930
    box-shadow 0px 3px 6px rgba(0, 0, 0, 0.16)
    line-height 88px
    font-size 20px
    font-weight bold
    color #FFFFFF
    text-align center
    .el-icon-close
      position absolute
      right 30px
      top 35px
      color #fff
  .preview-content
    width 1250px
    margin 0 auto
    position relative
    .process-name
      position absolute
      right 10px
      top 10px
      color #fff
      font-weight bold
      font-size 16px
    .tab-content
      border 1px solid #373A43
      overflow-y auto
      .preview-code
        padding-top 40px
        box-sizing border-box
        width 760px
        margin 0 auto
</style>
