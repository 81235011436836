<template>
  <div class="preview-bpmn" :class="{'shade-type':showShade}" v-loading="loading">
    <div class="header" v-if="showShade">
      预览
      <i class="el-icon-close" @click="closeThis"></i>
    </div>
    <div class="main-panel" :style="showShade?'height:'+wrapHeight:''">
      <div class="bpmn-viewer" ref="bpmnBox"></div>
    </div>
  </div>
</template>

<script>
import BpmnModeler from 'bpmn-js/lib/Viewer'
export default {
  name: 'previewBpmn',
  data () {
    return {
      loading: true,
      bpmnViewer: ''
    }
  },
  props: {
    XMLString: {
      type: String,
      default: ''
    },
    nodeList: {
      type: Array,
      default: () => { return [] }
    },
    showShade: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    wrapHeight: () => {
      return document.documentElement.clientHeight - 88 + 'px'
    }
  },
  mounted () {
    const that = this
    this.bpmnViewer = new BpmnModeler({
      container: this.$refs.bpmnBox
    })
    try {
      const warnings = async function () {
        await that.bpmnViewer.importXML(that.XMLString)
        that.bpmnViewer.get('canvas').zoom('fit-viewport', 'auto')
        const ElementRegistry = that.bpmnViewer.get('elementRegistry')
        const overlays = that.bpmnViewer.get('overlays')
        const list = ElementRegistry.getAll().filter(e => {
          return ['bpmn:StartEvent', 'bpmn:SubProcess', 'bpmn:Task'].indexOf(e.type) !== -1
        })
        const getNodeHandler = function (id) {
          const nodeItem = that.nodeList.find(e => {
            return e.code === id
          })
          let text = ''
          nodeItem.handler && nodeItem.handler.forEach((e, i) => {
            text += e.name
            text += i === nodeItem.length ? ',' : ''
          })
          return text || (nodeItem.type === 'bpmn:StartEvent' ? '所有人' : '-')
        }
        list.forEach(e => {
          const item = e.businessObject
          overlays.add(e, {
            position: {
              left: item.$type === 'bpmn:StartEvent' ? -92 : -60,
              top: item.$type === 'bpmn:StartEvent' ? -24 : -2
            },
            html: `<div class="bpmn-cover-panel">
              <span class="name">${item.name || '-'}</span>
              <p class="ellipsis2" title="${getNodeHandler(item.id)}">
                ${getNodeHandler(item.id)}
              </p>
            </div>`
          })
        })
        that.loading = false
      }
      warnings()
      console.log('rendered')
    } catch (err) {
      console.log('error rendering', err)
    }
  },
  methods: {
    closeThis () {
      this.$emit('closeThis')
    }
  }
}
</script>

<style lang="stylus" scoped>
.shade-type
  width 100%
  height 100%
  position fixed
  top 0
  left 0
  background #1E1E23
  z-index 999
.preview-bpmn
  .header
    width 100%
    height 88px
    background #272930
    box-shadow 0px 3px 6px rgba(0, 0, 0, 0.16)
    font-size 20px
    font-family Microsoft YaHei
    font-weight bold
    color #FFFFFF
    line-height 88px
    text-align center
    position relative
    .el-icon-close
      position absolute
      top 36px
      right 40px
  .main-panel
    height 782px
    position relative
    z-index 3
    .bpmn-viewer
      width 100%
      height 100%
</style>
