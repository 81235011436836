<template>
  <div class="w-work-center-wrap">
    <div class="container">
      <div class="search-wrap">
        <div class="search">
          <el-autocomplete
            hide-loading
            class="w-input-sty input-solid"
            v-model="searchVal"
            popper-class="my-autocomplete"
            :fetch-suggestions="querySearch"
            placeholder="请输入流程名称"
            @select="selectProcess"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <template slot-scope="{ item }">
              <span
                class="search-item-icon"
                :class="item.icon"
                :style="{ background: item.iconColour }"
              ></span>
              <span>{{ item.name }}</span>
              <span class="desc">{{ item.desc }}</span>
            </template>
          </el-autocomplete>
        </div>
      </div>
      <div class="workOrder-container" id="workOrder-container">
        <div class="workOrder-btns-container" v-if="navList.length > 0">
          <div class="btns-wrap" id="btns-wrap">
            <div
              class="btns-row"
              v-for="(item, index) in navList"
              :key="index"
              :id="'workOrder' + index"
            >
              <div class="title">{{ item.groupName }}</div>
              <div class="btns" v-if="item.children.length > 0">
                <div
                  class="btn"
                  v-for="(item2, key) in item.children"
                  :key="key"
                  @mouseover="mouseOver(index, key)"
                  @mouseleave="mouseLeave(index, key)"
                >
                  <div class="option" v-show="item2.showOption">
                    <div
                      class="item"
                      v-for="(icon, i) in btnList"
                      :key="i"
                      @click="setting(i, item2)"
                    >
                      <span>
                        <i :class="icon.icon"></i>
                      </span>
                      <span>{{ icon.name }}</span>
                    </div>
                  </div>
                  <div
                    class="icon-img"
                    :style="{ background: item2.state === 1 ? '#3A3B41' : item2.iconColour }"
                  >
                    <i :class="[item2.icon]"></i>
                  </div>
                  <div>
                    <div class="name ellipsis">{{ item2.name }}</div>
                    <div class="desc ellipsis">{{ item2.desc }}</div>
                  </div>
                </div>
              </div>
              <div v-else class="no-data">
                <p>该分组暂无流程</p>
              </div>
            </div>
          </div>
          <div class="nav">
            <ul id="nav">
              <li v-for="(item, index) in navList" :key="index" :class="{ 'active': item.locate }">
                <span @click="locate(index)">
                  <i v-if="item.locate" class="icon-work-point"></i>
                  {{ item.groupName }}
                </span>
              </li>
            </ul>
            <div class="group">
              <span @click="setGroup()">分组管理</span>
            </div>
          </div>
        </div>
        <div v-else class="no-data">
          <div>
            <img src="@/assets/images/public/not-plandata.png" />
            <p>暂无相关数据</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 二次弹窗（启用、停用、删除） -->
    <div v-if="showDialog" class="dialog-wrap">
      <el-dialog
        :visible.sync="showDialog"
        width="472px"
        top="350px"
        :show-close="true"
        custom-class="w-block-dialog"
        :before-close="closeDialog"
      >
        <div
          slot="title"
          class="headerTitle"
        >{{ dialogType === 0 ? '启用' : (dialogType === 1 ? '停用' : (dialogType === 2 ? '复制' : '删除')) }}</div>
        <div class="input-wrap">
          <div class="content" v-if="dialogType !== 2">
            <svg
              t="1636597978286"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="10242"
              width="16"
              height="16"
            >
              <path
                d="M512 512m-448 0a448 448 0 1 0 896 0 448 448 0 1 0-896 0Z"
                fill="#FF754C"
                p-id="10243"
              />
              <path
                d="M447.104 726.784a69.696 69.696 0 0 1 69.696-69.632 69.632 69.632 0 0 1 69.632 69.632 69.632 69.632 0 0 1-69.632 69.632 69.632 69.632 0 0 1-69.696-69.632z m5.76-204.288v-256a64 64 0 0 1 64-64 64 64 0 0 1 64 64v256a64 64 0 0 1-64 64 64 64 0 0 1-64.256-64z"
                fill="#FFFFFF"
                p-id="10244"
              />
            </svg>
            <span class="desc">{{ dialogDescription }}</span>
          </div>
          <div class="content" v-else>
            <span>*</span>
            <span>复制到项目</span>
            <div class="w-input-sty">
              <el-select
                popper-class="w-block-select-down yellow-select-panel"
                v-model="copyProjectIds"
                size="small"
                placeholder="请选择"
                filterable
                multiple
                style="width: 280px;"
              >
                <el-option
                  v-for="item in copyProjectIdList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="btns-wrap">
          <div class="btns">
            <span class="comfirm" @click="comfirm">确认</span>
            <span class="cancal" @click="closeDialog">取消</span>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 分组管理 -->
    <el-drawer
      size="560px"
      class="black-drawer eidit-work-drawer"
      :visible.sync="groupManagement"
      :with-header="false"
      direction="rtl"
      ref="drawer"
      wrapperClosable
      title="分组管理"
    >
      <div class="drawer-header">
        <span>分组管理</span>
        <i @click="groupManagement = false" class="el-icon-close"></i>
      </div>
      <div class="drawer-content">
        <div class="title-box">
          <div class="title">分组信息</div>
          <div class="add-btn" @click="addGroup">添加分组</div>
        </div>
        <div class="sort-box">
          <vuedraggable class="wrapper" v-model="flowGroupList">
            <transition-group>
              <div class="sort-item" v-for="(item,index) in flowGroupList" :key="item.id">
                <i class="el-icon-remove-outline" @click="delFlowGroupItem(index)"></i>
                <el-input
                  v-model="item.groupName"
                  placeholder="请输入内容"
                  class="w-input-sty input-solid"
                  style="width:240px;margin:0 20px"
                ></el-input>
                <i class="icon-unfold"></i>
              </div>
            </transition-group>
          </vuedraggable>
        </div>
      </div>
      <div class="drawer-footer">
        <div class="save-btn" @click="saveFlowGroupList">保存</div>
        <div class="cancel-btn" @click="groupManagement = false">取消</div>
      </div>
    </el-drawer>

    <!-- 工单设置预览页面 -->
    <div class="preview-page-wrap" v-if="showPreviewDialog">
      <div v-if="configId">
        <previewComp
          :configId="configId"
          :processName="processName"
          @closePreviewEmit="closePreviewEmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import vuedraggable from 'vuedraggable'
import previewComp from '../components/previewComp.vue'
import { powerAuth } from '@/utils'

export default {
  data () {
    return {
      input: '',
      flowGroupList: '',
      groupManagement: false,
      searchVal: '',
      searchList: [],
      filtroProject: '',
      navList: [], // 流程列表数据
      index1: 0, // 流程列表的下标
      index2: 0, // 流程列表的children的下标
      anchor: 0, // 锚点
      btnList: [{
        name: '查看',
        icon: 'icon-work-scan'
      }, {
        name: '编辑',
        icon: 'icon-work-edit'
      }, {
        name: '启用',
        icon: 'icon-work-enable'
      }, {
        name: '复制',
        icon: 'icon-work-copy'
      }, {
        name: '删除',
        icon: 'icon-work-delet'
      }],
      showDialog: false,
      workOrderId: '', // 工单流程id
      copyProjectIdList: '', // 可复制的项目列表
      copyProjectIds: '', // 复制后的项目ID
      dialogType: 0, // 弹框类型。0：启动；1：停用；2:复制；3：删除
      dialogDescription: '', // 弹框显示文本
      showPreviewDialog: false,
      configId: 0,
      processName: ''
    }
  },
  components: {
    vuedraggable,
    previewComp
  },
  props: {
    projectOptions: {
      type: Array,
      default: function () {
        return []
      }
    },
    projectId: {
      type: Number,
      default: 0
    },
    projectName: {
      type: String,
      default: ''
    }
  },
  watch: {
    projectId (newVal, oldVal) {
      this.filtroProject = this.projectId
      this.filterProject()
      this.anchor = 0
      this.getList('init')
      this.$nextTick(() => {
        document.getElementById('btns-wrap').scrollTo({
          behavior: 'smooth', // 平滑过渡
          top: 0
        })
      })
    }
  },
  mounted () {
    this.filtroProject = this.projectId
    this.filterProject()
    this.getList('init')
  },
  methods: {
    delFlowGroupItem (idx) {
      this.flowGroupList.splice(idx, 1)
    },
    addGroup () {
      const obj = {
        code: '',
        groupName: '',
        id: 0,
        projectId: this.projectId,
        state: 0
      }
      this.flowGroupList.push(obj)
    },
    getFlowGroupList () {
      const postData = {
        code: '',
        groupId: '',
        groupName: '',
        projectId: this.projectId,
        sort: ''
      }
      this.axios.post(api.getFlowGroupList, postData).then(res => {
        this.flowGroupList = res.data.data.filter(item => {
          return item.id !== 0
        })
      })
    },
    saveFlowGroupList () {
      const postdata = {}
      postdata.projectId = this.projectId
      postdata.flowGroupList = this.flowGroupList.map((item, index) => {
        return {
          code: item.code,
          groupId: item.id,
          groupName: item.groupName,
          projectId: item.projectId,
          sort: index + 1
        }
      })
      this.axios.post(api.saveFlowGroupList, postdata).then(res => {
        if (res.data.code === 0) {
          this.$message.success('成功保存!')
          this.groupManagement = false
          this.filterProject()
          this.getList('init')
        }
      })
    },
    querySearch (queryString, cb) {
      if (queryString) {
        const params = {
          code: '',
          configName: queryString,
          groupId: '',
          projectId: this.filtroProject
        }
        this.axios.post(api.searchFlowConfigListByName, params)
          .then((res) => {
            if (res.data.code === 0) {
              this.searchList = res.data.data.map(item => {
                return item
              })
              cb(this.searchList)
            }
          })
      }
    },
    selectProcess (item) {
      this.$router.push({
        path: '/formSubmit',
        query: {
          id: item.id,
          projectId: this.projectId,
          name: item.name
        }
      })
    },
    filterProject () {
      this.copyProjectIdList = this.projectOptions.filter(item => {
        return item.id !== this.filtroProject
      })
    },
    getList (type) { // 获取流程列表数据
      // type为init时，是列表首次加载(含首次进入页面和切换项目)
      const params = {
        code: '',
        groupId: '',
        groupName: '',
        projectId: this.filtroProject,
        sort: ''
      }
      this.axios.post(api.getFlowConfigListByGroup, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.navList = res.data.data.map((item, index) => {
              const obj = {
                groupId: item.groupId,
                groupName: item.groupName,
                children: [],
                locate: type === 'init' && index === 0 ? true : (index === this.anchor)
              }
              if (item.children && item.children.length > 0) {
                obj.children = item.children.map(item2 => {
                  const obj2 = {
                    id: item2.id,
                    code: item2.code,
                    name: item2.name,
                    projectId: item2.projectId,
                    groupId: item2.groupId,
                    groupName: item2.groupName,
                    desc: item2.desc,
                    icon: item2.icon,
                    iconColour: item2.iconColour,
                    state: item2.state,
                    showOption: false
                  }
                  return obj2
                })
              }
              return obj
            })
            // 自适应高度
            this.$nextTick(function () {
              if (this.navList.length > 0) {
                const dom = document.getElementById('btns-wrap')
                const dom2 = document.getElementById('nav')
                dom.style.height = window.innerHeight - 260 + 'px'
                dom2.style.height = window.innerHeight - 420 + 'px'
                window.addEventListener('resize', () => {
                  dom.style.height = window.innerHeight - 260 + 'px'
                  dom2.style.height = window.innerHeight - 420 + 'px'
                })
              } else {
                const dom = document.getElementById('workOrder-container')
                dom.style.height = window.innerHeight - 260 + 'px'
                window.addEventListener('resize', () => {
                  dom.style.height = window.innerHeight - 260 + 'px'
                })
              }
            })
          }
        })
    },
    mouseOver (index, key) {
      this.index1 = index
      this.index2 = key
      this.navList[index].children[key].showOption = true
      if (this.navList[index].children[key].state === 0) {
        this.btnList[2].name = '停用'
        this.btnList[2].icon = 'icon-work-disable'
      } else {
        this.btnList[2].name = '启用'
        this.btnList[2].icon = 'icon-work-enable'
      }
    },
    mouseLeave (index, key) {
      this.index1 = index
      this.index2 = key
      this.navList[index].children[key].showOption = false
    },
    setting (i, item) { // i: 按钮类型（查看，编辑，启用/停用，复制，删除）
      this.workOrderId = item.id
      // console.log('item', item)
      switch (i) {
        case 0: // 查看
          if (!powerAuth(233, 'show')) {
            this.hasnotPermission()
            return false
          }
          document.body.style.overflowY = 'hidden'
          this.configId = item.id
          this.processName = item.name
          this.showPreviewDialog = true
          break
        case 1: // 编辑
          if (!powerAuth(233, 'redact')) {
            this.hasnotPermission()
            return false
          }
          this.$router.push({
            path: `/basicForm?active=0&id=${this.workOrderId}&projectId=${this.projectId}&edit=1`
          })
          break
        case 2: // 启用 或 停用
          if (!powerAuth(233, 'enable_deactivate')) {
            this.hasnotPermission()
            return false
          }
          if (item.state === 0) { // 停用
            this.dialogType = 1
            this.dialogDescription = '停用后，该流程不可再被使用（已发起的工单不受影响）。是否确认停用？'
          } else { // 启用
            this.dialogType = 0
            this.dialogDescription = '启用后，将开放给项目内的所有成员（如允许外部发起，将显示在商户小程序端）。是否确认启用？'
          }
          this.showDialog = true
          break
        case 3: // 复制
          if (!powerAuth(233, 'copy')) {
            this.hasnotPermission()
            return false
          }
          this.dialogDescription = ''
          this.showDialog = true
          this.dialogType = 2
          break
        case 4: // 删除
          if (!powerAuth(233, 'del')) {
            this.hasnotPermission()
            return false
          }
          this.showDialog = true
          this.dialogDescription = '删除为不可逆操作，请确认是否删除？'
          this.dialogType = 3
          break
      }
    },
    closePreviewEmit () {
      document.body.style.overflowY = 'auto'
      this.showPreviewDialog = false
    },
    // 删除
    showDelDialog () {
      this.showDialog = true
    },
    closeDialog () {
      this.showDialog = false
      this.copyProjectIds = ''
      this.dialogDescription = ''
      this.dialogType = ''
    },
    comfirm () {
      switch (this.dialogType) {
        case 0: // 启用
          this.showDialog = false
          this.startUp()
          break
        case 1: // 停用
          this.showDialog = false
          this.deactivate()
          break
        case 2: // 复制
          if (this.copyProjectIds.length === 0) {
            this.$message({
              type: 'warning',
              message: '请选择项目'
            })
            return false
          }
          this.showDialog = false
          this.copy()
          break
        case 3: // 删除
          this.showDialog = false
          this.delete()
          break
      }
    },
    startUp () { // 启用
      const params = {
        id: this.workOrderId,
        state: 0
        // 暂时关闭，后面再打开
        // version: '' // 防止两个人同时修改
      }
      this.axios.post(api.workConfigStartOrStop, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.getList()
            this.$message({
              type: 'success',
              message: '启用成功'
            })
          }
        })
    },
    deactivate () { // 停用
      const params = {
        id: this.workOrderId,
        state: 1
        // 暂时关闭，后面再打开
        // version: '' // 防止两个人同时修改
      }
      this.axios.post(api.workConfigStartOrStop, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.getList()
            this.$message({
              type: 'success',
              message: '停用成功'
            })
          }
        })
    },
    copy () { // 复制
      const params = {
        id: this.workOrderId,
        projectIdList: this.copyProjectIds
      }
      this.axios.post(api.workConfigCopy, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '复制成功'
            })
          }
          this.copyProjectIds = ''
        })
    },
    delete () { // 删除
      if (!this.navList[this.index1].children[this.index2].state) {
        this.$message({
          type: 'error',
          message: '启用中的流程无法删除，请停用后再删除'
        })
      } else {
        const params = {
          configId: this.workOrderId
          // version: ''
        }
        this.axios.post(api.deleteFlowConfig, params)
          .then((res) => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.getList()
            }
          })
      }
    },
    locate (index) { // 锚点定位
      this.anchor = index
      // 右侧标题
      this.navList.forEach((item, i) => {
        if (index === i) {
          item.locate = true
        } else {
          item.locate = false
        }
      })
      // 滑动
      let topVal = 0
      for (let i = 0; i < index; i++) {
        const id = 'workOrder' + i
        const height = document.getElementById(id).offsetHeight
        topVal += height
      }
      this.$nextTick(() => {
        document.getElementById('btns-wrap').scrollTo({
          behavior: 'smooth', // 平滑过渡
          top: topVal
        })
      })
    },
    setGroup () {
      if (!powerAuth(233, 'groupmanage')) {
        this.hasnotPermission()
        return false
      }
      this.getFlowGroupList()
      this.groupManagement = true
    },
    hasnotPermission () {
      this.$message.error({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.drawer-footer
  position absolute
  bottom 0
  left 0
  display flex
  justify-content center
  .save-btn
    width 130px
    height 36px
    line-height 36px
    background #F89407
    text-align center
    color #fff
    border-radius 4px
    cursor pointer
  .cancel-btn
    width 130px
    height 36px
    line-height 36px
    background #373A43
    text-align center
    color #fff
    margin-left 20px
    border-radius 4px
    cursor pointer
.drawer-header
  height 70px
  display flex
  justify-content space-between
  align-items center
  color #fff
.drawer-content
  padding 40px
  height calc(100% - 147px)
  overflow-y scroll
  &::-webkit-scrollbar
    width 6px
    height 16px
    background-color #373a43
  &::-webkit-scrollbar-track
    -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
    border-radius 10px
    background-color #373a43
  &::-webkit-scrollbar-thumb
    border-radius 10px
    height 10px
    -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
    background-color #f89407
    cursor pointer
  .sort-box
    margin-top 20px
    display flex
    justify-content center
    align-items center
    flex-direction column
  .sort-item
    display flex
    align-items center
    margin-top 10px
    .w-input-sty>>>
      input
        width 100% !important
  color #fff
  .title-box
    display flex
    justify-content space-between
    position relative
    .title
      color #fff
      position relative
      &:before
        content ''
        display block
        position absolute
        left -9px
        top 3px
        height 19px
        width 2px
        background #f89407
    .add-btn
      color orange
      cursor pointer
.w-work-center-wrap
  display flex
  margin-left 204px
  width calc(100% - 226px)
  .container
    width 100%
    background-color #272930
    .search-wrap
      padding 0 32px
      display flex
      justify-content space-between
      align-items center
      height 88px
      background #272930
      box-sizing border-box
      .search
        display flex
        .w-input-sty
          width 320px
          height 40px
          background #373A43
          border-radius 2px
          & >>> .el-input__inner
            width 320px
            height 40px !important
    .workOrder-container
      border-radius 2px
      .workOrder-btns-container
        display flex
        justify-content space-between
        .btns-wrap
          padding 0 40px
          flex 1
          background #272930
          overflow-y auto
          .btns-row
            min-height 155px
            border-bottom 1px solid #373A43
            &:last-child
              border none
            .title
              height 75px
              font-size 14px
              font-weight 400
              line-height 75px
              color #B2B3BD
            .btns
              display flex
              flex-wrap wrap
              .btn
                position relative
                display flex
                margin-right 10px
                margin-bottom 33px
                height 64px
                align-items center
                width 270px
                .icon-img
                  margin-right 9px
                  width 48px
                  height 48px
                  line-height 48px
                  text-align center
                  font-size 24px
                  color #fff
                  border-radius 8px
                .name
                  width 160px
                  font-size 18px
                  font-weight 400
                  color #FFFFFF
                .desc
                  width 200px
                  font-size 14px
                  font-weight 400
                  color #808191
                .option
                  position absolute
                  display flex
                  align-items center
                  justify-content space-around
                  margin-left 8px
                  padding 0 10px
                  color #fff
                  width 264px
                  height 64px
                  background rgba(0, 0, 0, 0.8)
                  border-radius 16px
                  z-index 10
                  .item
                    cursor pointer
                    text-align center
                    width 24px
                    font-size 12px
                    font-weight 400
                    color #fff
                    span
                      display inline-block
                      i
                        margin-bottom 4px
                        color #fff
                        font-size 18px
            .no-data
              padding 0
              text-align center
              font-size 14px
              color #b2b3bd
          &::-webkit-scrollbar
            width 4px
          &::-webkit-scrollbar-track
            border-radius 10px
          /* 滚动条的滑轨背景颜色 */
          &::-webkit-scrollbar-thumb
            background-color #373A43
            border-radius 10px
        .nav
          width 180px
          background #272930
          font-size 14px
          font-weight 400
          line-height 48px
          color #808191
          box-sizing border-box
          // box-shadow -5px 0px 1px #000
          ul
            margin 40px auto auto 28px
            overflow-y auto
            li
              text-indent 28px
              span
                display flex
                align-items center
                cursor pointer
            .active
              text-indent 0
              color #FFF
              font-size 18px
              font-weight 500
              i
                color #fff
                font-size 8px
                transform scale(0.5)
                margin-right 8px
            &::-webkit-scrollbar
              width 4px
            &::-webkit-scrollbar-track
              border-radius 10px
            /* 滚动条的滑轨背景颜色 */
            &::-webkit-scrollbar-thumb
              background-color #373A43
              border-radius 10px
          .group
            display flex
            align-items center
            justify-content center
            margin-top 24px
            width 180px
            border-radius 0px
            span
              cursor pointer
              display inline-block
              width 120px
              height 40px
              background #373A43
              border-radius 20px
              font-size 14px
              font-weight 400
              line-height 40px
              text-align center
              color #fff
.dialog-wrap
  background #272930
  box-shadow 0px 4px 50px rgba(0, 0, 0, 0.1)
  border-radius 2px
  .headerTitle
    display flex
    align-items center
    font-size 18px
    font-weight bold
    color #fff
  .input-wrap
    display flex
    align-items center
    .title
      width 98px
      color #fff
      font-size 14px
      text-align right
  .content
    display flex
    align-items center
    margin 0 auto
    color #fff
    font-size 14px
    // .desc
    //   width 350px
    svg
      display inline
      margin-right 13px
    span
      display inline-block
      &:first-child
        color #f00
      &:nth-child(2)
        margin-right 8px
    & >>> .el-input__inner
      width 280px !important
      min-height 40px !important
  .btns-wrap
    display flex
    justify-content center
    margin-top 28px
    .btns
      span
        display inline-block
        width 88px
        height 36px
        line-height 36px
        text-align center
        color #fff
        border-radius 4px
        cursor pointer
        &.comfirm
          background-color #F89407
          &:hover
            background-color #DB8307
        &.cancal
          background-color #373A43
          margin-left 24px
.my-autocomplete
  span
    margin-right 10px
  .search-item-icon
    display inline-block
    width 20px
    height 20px
    line-height 20px
    border-radius 5px
    text-align center
    background #f00
    color #fff
  .desc
    font-size 12px
</style>
